import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import statusEnum from '../../../enums/statusEnum';

import Button from '../../Button';
import Modal from '../../Modal';
import PasswordInput from '../../PasswordInput';

import './Login.css';

function Login(props) {
  const {
    firstName,
    email,
    password,
    status,
    isPasswordModalShown,
    error,
    timer,
    onPasswordChange,
    onFormSubmit,
    onForgetPassword,
    onPasswordModalHide,
  } = props;

  return (
    <form onSubmit={onFormSubmit}>
      <Typography variant="h1" color="secondary.light" className="SignInTitle" fontWeight="900">
        Hello {firstName}!
      </Typography>
      <p className="middle-text">
        It looks like you already have an account with us. Please enter your password to login.
      </p>
      <Typography variant="body1" className="user-email padded-bottom bold" color="primary">
        {email}
      </Typography>
      <div>
        <div
          className={classnames('padded-bottom', { 'input-alert': status === statusEnum.ERROR })}
        >
          <Typography
            variant="subtitle2"
            color="secondary.light"
            className="input-label EmailLabel"
          >
            Password
          </Typography>
          <PasswordInput password={password} onPasswordChange={onPasswordChange} />
          {error && timer && (
            <div className="input-alert-text">
              <p>
                {error} ({timer}s)
              </p>
            </div>
          )}
          {error && !timer && (
            <div className="input-alert-text">
              <p>{error}</p>
            </div>
          )}
        </div>
        <Button className="btn-primary" disabled={password?.length < 1}>
          Log in
        </Button>
        <div className="padded-top-large">
          <Button className="blue-link ForgetPasswordLink" onClick={onForgetPassword}>
            Forgot your password?
          </Button>
        </div>
        <Modal className="modal-small" show={isPasswordModalShown} onHide={onPasswordModalHide}>
          <Modal.Body className="kale">
            <Typography variant="h3" color="secondary.dark">
              Check your email
            </Typography>
            <p>
              Please follow the instruction we sent to &nbsp;
              <strong>{email}</strong>
              &nbsp; to reset your password.
            </p>
            <Button className="btn-primary" onClick={onPasswordModalHide}>
              Okay
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </form>
  );
}

Login.propTypes = {
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  isPasswordModalShown: PropTypes.bool,
  error: PropTypes.string,
  timer: PropTypes.number,
  onPasswordChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onForgetPassword: PropTypes.func.isRequired,
  onPasswordModalHide: PropTypes.func.isRequired,
};

Login.defaultProps = {
  isPasswordModalShown: false,
  error: null,
  timer: null,
};

export default Login;
