import React from 'react';
import ReactImageFallback from 'react-image-fallback';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import * as orderUtils from '../../../utils/orderUtils';

import Button from '../../Button';
import Currency from '../../Currency';
import loadingImage from '../../LoadingScreen/loading-heybo.gif';

import './OrderStatus.css';

function OrderStatus({ isDeliveryOrder, status, price, gglocationPhone }) {
  if (orderUtils.isOrderStatusCompleted(status)) {
    return (
      <div className="OrderStatus OrderStatusCompleted">
        <Typography variant="subtitle1" color="grey.main" fontWeight="600">
          {isDeliveryOrder ? 'Your order has been delivered. ' : 'Your order is ready for pickup. '}
        </Typography>
        <Typography variant="subtitle2" color="grey.main">
          Thank you for ordering with us!
        </Typography>
        <Typography variant="body2" color="grey.main" className="ContactHelp">
          <p>
            For support, contact us at&nbsp;
            <a href="mailto:support@saladstopgroup.com" className="blue-link">
              support@saladstopgroup.com
            </a>
          </p>
          <p>
            or call the outlet directly at&nbsp;
            <a href={`tel:${gglocationPhone}`} className="blue-link">
              {gglocationPhone}
            </a>
          </p>
          <p>
            You can also call Heybo Customer Support at&nbsp;
            <a href="tel:+6569907886" className="blue-link">
              +6569907886
            </a>
            , Monday-Friday 09:00AM to 06:00PM. Please have your Order Number available.
          </p>
        </Typography>
      </div>
    );
  }

  if (orderUtils.isOrderStatusCancelled(status)) {
    return (
      <div className="OrderStatus OrderStatusCancelled">
        <Typography variant="subtitle1" color="grey.main" fontWeight="600">
          Your order has been cancelled.
        </Typography>
        <Typography variant="subtitle2" color="grey.main">
          We hope to see you again.
        </Typography>
        <Typography variant="body2" color="grey.main" className="ContactHelp">
          <p>
            For support, contact us at&nbsp;
            <a href="mailto:support@saladstopgroup.com" className="blue-link">
              support@saladstopgroup.com
            </a>
          </p>
          <p>
            or call the outlet directly at&nbsp;
            <a href={`tel:${gglocationPhone}`} className="blue-link">
              {gglocationPhone}
            </a>
          </p>
          <p>
            You can also call Heybo Customer Support at&nbsp;
            <a href="tel:+6569907886" className="blue-link">
              +6569907886
            </a>
            , Monday-Friday 09:00AM to 06:00PM. Please have your Order Number available.
          </p>
        </Typography>
      </div>
    );
  }

  if (orderUtils.isOrderStatusAccepted(status)) {
    return (
      <div className="OrderStatus OrderStatusAccepted">
        <Typography variant="subtitle1" color="grey.main" fontWeight="600">
          Thank you for your order!
        </Typography>
        <Typography variant="subtitle2" color="grey.main">
          <span>
            <Currency value={price} />
            &nbsp; has been charged to your credit card.
          </span>
        </Typography>
        <Typography variant="subtitle2" color="grey.main">
          Your purchase has been credited to our{' '}
          <Button className="blue-link" link="/loyalty">
            rewards program
          </Button>{' '}
          but may take up to 24 hours to appear.
        </Typography>
        <Typography variant="body2" color="grey.main" className="ContactHelp">
          <p>
            For support, contact us at&nbsp;
            <a href="mailto:support@saladstopgroup.com" className="blue-link">
              support@saladstopgroup.com
            </a>
          </p>
          <p>
            or call the outlet directly at&nbsp;
            <a href={`tel:${gglocationPhone}`} className="blue-link">
              {gglocationPhone}
            </a>
          </p>
          <p>
            You can also call Heybo Customer Support at&nbsp;
            <a href="tel:+6569907886" className="blue-link">
              +6569907886
            </a>
            , Monday-Friday 09:00AM to 06:00PM. Please have your Order Number available.
          </p>
        </Typography>
      </div>
    );
  }

  return (
    <div className="OrderStatus OrderStatusAwaiting">
      <ul>
        <li>
          <Typography variant="subtitle1" color="grey.main" fontWeight="600">
            Awaiting store confirmation...
          </Typography>
        </li>
        <li className="timer">
          <ReactImageFallback alt="" src={loadingImage} />
        </li>
      </ul>
    </div>
  );
}

OrderStatus.propTypes = {
  isDeliveryOrder: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  gglocationPhone: PropTypes.string.isRequired,
};

export default OrderStatus;
